import * as React from 'react'
import { Link } from 'gatsby'
//import { StickyContainer, Sticky } from 'react-sticky';

import IndexLayout from '../layouts'

import styled from "@emotion/styled";

import HomeSlider from "../components/HomeSlider";
import StudioPlx from "../components/swipesliders/StudioPlx";
import HeaderDesktop from "../components/HeaderDesktop";

import {UserBookings} from "../components/user/Bookings";



const logo = require('../../assets/images/V7_000_Logo.png')

const CenterV = styled.img`
margin-top:10px;
margin-bottom:6px;
width:4%;
height:auto;
`;

  const StyledHeader = styled.header`
align-items: center;
text-align: center;
z-index:1;

background:white;
opacity:1;
  @media screen and (max-width: 1200px) {

display:none;
   }

`

const Div = styled.div`
height:3em;
    @media screen and (max-width: 1200px) {

display:none;
   }
`

const StyledSlider = styled(StudioPlx)`

`



const Bookings = ( {pathContext: { locale }}) => (
  <IndexLayout locale={locale}>

    <HeaderDesktop></HeaderDesktop>

<UserBookings/>
  </IndexLayout>
)

export default Bookings
