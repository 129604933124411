import 'react-dates/lib/css/_datepicker.css';
import Sticky from 'react-stickynode';
import {Link} from "gatsby";
const sleep = m => new Promise(r => setTimeout(r, m));
import { FormattedMessage } from 'react-intl';


import 'react-slidedown/lib/slidedown.css'



//import Avatar, { ConfigProvider } from 'react-avatar';

var moment = require('moment');
import * as React from 'react'
import styled from '@emotion/styled'
import {BookingTemplate} from "./BookingTemplate";
import {retrieveCharges, retrievePaymentIntents} from "../stripe/functions";
import {getInvoiceInfo, getReservation} from "../cloudbeds/functions";
//import {getAvatar, getFullName, getProfile} from "../auth/authFunctions";

require('../../styles/global.css');
//const fs = require('fs');

// standard rates for studio, studio plus and suite



const Outer = styled.div`
display:flex;
justify-content: space-between;
width:80%;
margin-left:auto;
margin-right:auto;
@media screen and (max-width:1200px){
width:100%;
}
`

const Inner = styled.div`
  border:1px solid rgba(68,68,68,.04);  
background:rgba(68,68,68,.02);
border-radius: 2px;
text-align:center;
padding-top: 40px;
height:360px;
width:360px;
box-shadow: 1px 2px 4px rgba(0,0,0,0.05);

@media screen and (max-width:1200px){
width:100%;
}

`

const StyledSticky = styled(Sticky)`
width:40%;
@media screen and (max-width:1200px){
display:none;
}

`



const Div = styled.div`
height:auto;
width:80%;
padding-left:3%;

@media screen and (max-width:1200px){
width:100%;
padding-left:0px;
}
`

const Bookings = styled.div`

`

const Settings = styled.div`

`

const ProfileDiv = styled.div`

`

const A = styled.a`
  height:50px;
  border-top:1px solid rgba(68,68,68,.07);  
  width:100%;
  display:flex;
font-size: 1.15em;
  align-items:center;
justify-content:center;
  letter-spacing: 2px;
  transition:all ease .3s;
cursor:pointer;


  :hover{
background:rgba(68,68,68,.04);


}
`

const AA = styled.a`
  height:50px;
  border-top:1px solid rgba(68,68,68,.07);  
  border-bottom:1px solid rgba(68,68,68,.07);  
  width:100%;
  display:flex;
  align-items:center;
justify-content:center;
  letter-spacing: 2px;
  transition:all ease .3s;
cursor:pointer;


  :hover{
background:rgba(68,68,68,.04);




}
`
const StyledP = styled.p`
 font-family: "Theano Didot",Regular;
font-size: 180%;
font-style:italic;
line-height: 40px;
margin-top:30px;
color:rgb(68, 68, 68);
text-align:center !important;
  @media screen and (max-width: 1200px) {

   }


`;

const StyledLink = styled(Link)`


`


const ArrowImg = styled.img`
margin-left:5px;
margin-bottom:1px;
width:14px;
height:14px;
transition:all ease 1s;


`

const UserNav = styled.div`
margin-top:50px;
`


export class UserBookings extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      name:'',
    avatar:'',
      arrowRotation: 270,


    }
  }


  rotate(){
    let newRotation = this.state.arrowRotation;
    if(newRotation === 270){
      newRotation += 90;
    }
    else
    if(newRotation === 360){
      newRotation -= 90;
    }
    this.setState({
      arrowRotation: newRotation,
    })
  }


async componentDidMount(){
  const getAvatar =() =>{
    if(window.localStorage.profile!==undefined){
      let profile = JSON.parse(window.localStorage.profile)
      if(profile.picture !==undefined){
        return profile.picture
      }
      return ''
    }

    return ''


  }

  const getFullName =() =>{
    if(window.localStorage.profile!==undefined){
      let fullName = JSON.parse(window.localStorage.profile)["https://dev-uv101:eu:auth0:com/full_name"]
      if(fullName !==null && fullName!==undefined){
        return fullName
      }
      return ''
    }

    return ''

  }

  const charges = await retrieveCharges(window.localStorage.stripe_customer_id)
  const intents = await retrievePaymentIntents(window.localStorage.stripe_customer_id)

  let reservationIDs = []

  if(charges.data!=undefined || charges.data!=null) {
    for (let c = 0; c < charges.data.length; c++) {
      let utcSeconds = charges.data[c].created;
      let d = await new Date(0); // The 0 there is the key, which sets the date to the epoch
      let created = await d.setUTCSeconds(utcSeconds)
      await reservationIDs.push({
        reservationID: charges.data[c].metadata.reservationID,
        timestamp: created

      })
    }
  }

  if(intents.data!=undefined || intents.data!=null) {
    for (let c = 0; c < intents.data.length; c++) {
      let utcSeconds = intents.data[c].created;
      let d = await new Date(0); // The 0 there is the key, which sets the date to the epoch
      let created = await d.setUTCSeconds(utcSeconds)
      await reservationIDs.push({
        reservationID: intents.data[c].metadata.reservationID,
        timestamp: created

      })
    }
  }

    await reservationIDs.sort(function(a,b){
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      return b.date - a.date;
    });

  this.setState({
    reservationIDs: reservationIDs,
  })

  //only fetch last three reservations
  let reservations = []
  let newLength;
  if(reservationIDs.length>3){
    newLength = 3
  }
  else{
    newLength = reservationIDs.length
  }
  for(let r=0;r<newLength;r++){
    await sleep(1000)
    const reservation = await getInvoiceInfo(reservationIDs[r].reservationID)
    await reservations.push(reservation)
  }

  if(charges.object==='list'){
    this.setState({
      charges: charges,
    })
  }
  else{
    //todo: handle error
    //console.log('User have no charges or charges could not be loaded')
  }

  if(intents.object==='list'){
    this.setState({
      intents: intents,
    })
  }
  else{
    //todo: handle error
    //console.log('User have no intents or intents could not be loaded')
  }


  this.setState({
    avatar: getAvatar(),
    name: getFullName()
  })

}



  render() {
    // console.log("Suite_Blocker: " + suite_blocker["0"][1])

    return (
      <ProfileDiv>
        <Outer ref='stick-end' id={'sticky-end'}>

          <StyledSticky innerZ={99} enabled={true} top={150} bottomBoundary='#sticky-end'>
            <Inner>
              <UserNav>
                <StyledLink to={'/bookings'}> <A >BOOKINGS</A> </StyledLink>

                <StyledLink to={'/settings'}> <AA >SETTINGS</AA> </StyledLink>




              </UserNav>
            </Inner>
          </StyledSticky>
          <Div>
            <Bookings>
              <StyledP> Bookings</StyledP>
            </Bookings>
          </Div>
        </Outer>
      </ProfileDiv>

    )
  }
}
